<template>
  <div class="van-ellipsis">
    <span @click="showSelevt = true">{{ typeName }}<i
        class="iconfont icon-shaixuan tu-biao"></i></span>
    <van-popup
        v-model:show="showSelevt"
        position="right"
        teleport="body"
        round
        style="height: 100%;width: 60%;min-width: 300px"
    >
      <div class="dao-hang" :style="{'height':`${height}px`}">
        <div class="hua-dong">
          <van-cell-group>
            <van-cell
                size="large"
                :icon="vo.image"
                :title="vo.name"
                v-for="vo in typeList"
                :key="vo.id"
                @click="change(vo)"
            >
              <template #right-icon>
                <van-icon v-if="typeId.includes(vo.id)" class="text-zs" name="success"/>
              </template>
            </van-cell>
          </van-cell-group>
        </div>
        <div class="an-niu">
          <van-button type="primary" @click="showSelevt=false" block>确定</van-button>
        </div>


      </div>
    </van-popup>
  </div>
</template>

<script>
//头部服务类型

export default {
  name: 'SelectTopFuWuType',
}
</script>
<script setup>
import {useWindowSize} from '@vant/use';
import {useContext, reactive, ref, defineEmit, onMounted, computed} from 'vue'
import axios from "../../api/http";
import {useRoute} from 'vue-router'
import XEUtils from "xe-utils";

const {width, height} = useWindowSize();
const emit = defineEmit(['change'])
let showSelevt = ref(false)
let typeList = ref([])
const route = useRoute()
const ext = useContext()
let leaver = ref({
  activeId: '',
  activeIndex: 0,
  type_1: [],
  type_2: [],
})
onMounted(() => {
  setDefault()
  setList()
})

function setList() {
  axios.get("/portal/index/xiangMuType4").then((da) => {
    typeList.value = da.data.map(n => {
      n.image = XEUtils.last(n.image)?.url
      return n
    })
  })
}

//显示默认内容
function setDefault() {

  if (XEUtils.isString(route.query.type_1)) {
    leaver.value.type_1 = [
      {
        id: XEUtils.toInteger(route.query.type_1),
        name: route.query.type_name_1
      }
    ]
  } else if (XEUtils.isString(route.query.type_2)) {
    leaver.value.type_2 = [
      {
        id: XEUtils.toInteger(route.query.type_2),
        name: route.query.type_name_2
      }
    ]
  }
}


function change(item) {
  leaver.value.type_2 = []
  leaver.value.type_name_2 = []
  let data = {id: item.id, name: item.name}
  let status = XEUtils.remove(leaver.value.type_1, n => {
    return n.id == data.id
  })
  if (status.length === 0) {
    //删除失败 就执行添加
    leaver.value.type_1.push(data)
  }
  emit('change', item)
}

/**
 * 选中的类型名称
 * @type {ComputedRef<string|string>}
 */
let typeName = computed(() => {
  if (!XEUtils.isEmpty(leaver.value.type_1)) {
    return leaver.value.type_1.map(n => n.name).join('、')
  }
  if (!XEUtils.isEmpty(leaver.value.type_2)) {
    return leaver.value.type_2.map(n => n.name).join('、')
  }
  return '分类筛选'
})
/**
 * 选中的类型id
 * @type {ComputedRef<*[]|*[]|[]>}
 */
let typeId = computed(() => {
  if (!XEUtils.isEmpty(leaver.value.type_1)) {
    return leaver.value.type_1.map(n => n.id)
  }
  if (!XEUtils.isEmpty(leaver.value.type_2)) {
    return leaver.value.type_2.map(n => n.id)
  }
  return []
})

ext.expose({
  getWhere() {
    return {
      type_1: leaver.value.type_1.map(n => n.id).join(','),
      type_name_1: leaver.value.type_1.map(n => n.name).join(','),
      type_2: leaver.value.type_2.map(n => n.id).join(','),
      type_name_2: leaver.value.type_2.map(n => n.name).join(','),
    }
  }
})
</script>

<style scoped lang="less">
@import "../../assets/basis_css/style/var";

.tu-biao {
  color: @gray-5;
}

.dao-hang {
  background-color: @gray-2;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: center;
  align-items: stretch;
  height: 100%;

  /deep/ .van-cell__left-icon {
    line-height: 21px;
  }
  .hua-dong {
    flex: 1;
    overflow-y: auto;
  }

  .an-niu {
    height: 45px;
  }
}


</style>
