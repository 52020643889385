<template>
  <div class="van-ellipsis">
    <span @click="show = true">
      {{ selectConfig.name ? selectConfig.name : '地区筛选' }}
      <i class="iconfont icon-jiantou_zuoyouqiehuan tu-biao"></i>
    </span>

    <van-popup v-model:show="show" teleport="body" round position="bottom">
      <van-cascader
          v-model="cascaderValue"
          title="请选择"
          :field-names="{text:'name',value:'id',coe:'id'}"
          :options="options"
          @close="show = false"
          @change="onChange"
          @finish="onFinish"
      >
        <template #title>
          <a href="javascript:" @click="qing_kong">全部不选</a>
        </template>
      </van-cascader>
    </van-popup>
  </div>
</template>

<script>
//头部选择地区
export default {
  name: 'selectTopAddress',
}
</script>
<script setup>
import {useContext, ref, defineEmit, onMounted} from 'vue'
import http from "../../api/http";
import Cookie from 'js-cookie'

const emit = defineEmit(['change']) //数据改变事件
const cookieNmae = 'diZhiNamefdjkgh';// 储存地址名称
const ext = useContext()
let show = ref(false)
let cascaderValue = ref('')
let selectConfig = ref({id: '', name: ''}) //客户选的地址
let options = ref([])

onMounted(() => {
  setAddres()
  setDefaultAddress()
})

function setAddres() {
  http.get('/portal/index/get_di_qu').then(da => {
    options.value = da.data
  })
}

function qing_kong() {
  selectConfig.value.id = ''
  selectConfig.value.name = ''
  cascaderValue.value = ''
  onFinish({selectedOptions: []})
}

//选择后触发数据
function onFinish({selectedOptions}) {
  let list = selectedOptions.map(n => {
    let name = n.name.split('-')
    return {
      id: name.length === 2 ? n.parent_id: n.id , //如果选择的是全部，就使用上级id
      name: n.name.split('-')[0],
      parent_id: n.parent_id
    }
  })
  if (list.length) {
    selectConfig.value.id = list[list.length - 1].id
    selectConfig.value.name = list[list.length - 1].name
  }
  Cookie.set(cookieNmae, JSON.stringify(selectConfig.value), {
    expires: 30
  })
  show.value = false
  emit('change', selectConfig.value)
}


//设置默认值
function setDefaultAddress() {
  if (!Cookie.get(cookieNmae)) return
  let data = JSON.parse(Cookie.get(cookieNmae));
  cascaderValue.value = selectConfig.value.id = data.id
  selectConfig.value.name = data.name
}

function getConfig() {
  return selectConfig.value
}

ext.expose({
  //获取配置
  getAddressName() {
    return selectConfig.value.name
  },
  getALl() {
    return {
      address_id: selectConfig.value.id,
      address_name: selectConfig.value.name
    }
  }
})

</script>

<style scoped lang="less">
@import "../../assets/basis_css/style/var";

.tu-biao {
  color: @gray-5;
}
</style>
